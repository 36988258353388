
.audits-list {
}

.audit-quarter {
  width: 100%;
  min-height: 48px;
  padding: 0.5rem 1rem;
}
.audit-quarter:hover {
  background: rgba(0, 0, 0, 0.1);
}

.list-heading {
  font-size: 0.75rem;
  line-height: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
}
.list-content {
  font-size: 0.8rem;
  // cursor: pointer;
}

// .list-content:hover {
//   text-decoration: underline;
// }

.audit-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0.25rem 0;
}
