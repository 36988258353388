
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 46px repeat(3, auto);
  grid-auto-flow: unset;
  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    padding-left: 1rem;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
    &--bold {
      font-weight: 600;
    }
  }
  &__dropbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px dashed lightgrey;
    border-radius: $border-radius;
    padding: 1rem;
    margin: 1rem;
    font-size: 1.25rem;
  }
  .is-active {
    background: #42ab983b;
  }
  .button-row {
    padding-top: $padding1x;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

td.archive {
  width: 3rem;
  // background: rgba(0, 0, 0, 0);
  color: transparent;
  transition: all 200ms ease-in-out;
}

.table-row-enter-active,
.table-row-leave-active {
  transition: all 350ms;
}

.table-row-enter,
.table-row-leave-to {
  opacity: 0;
}

.table-row-move {
  transition: all 300ms;
}

// td.archive > .fa-2x {
//   font-size: 1.5rem;
// }

.table .table-header > th {
  // color: red;
  cursor: pointer;
}

.table > tbody > tr:hover {
  // .archive {
  //   background: rgba(232, 49, 86, 1);
  //   color: white;
  // }
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sub-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: search;
  .subtitle {
    width: 100%;
  }
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.dateFilter {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.search-bar {
  max-width: 400px;
}

.context-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem;
  span {
    font-size: 1.2rem;
    // width: 1rem;
    // height: 1rem;
  }
  .pdf-report:hover,
  .survey:hover,
  .archive:hover {
    transform: scale(1.3);
  }

  .archive:hover {
    color: red;
  }
}

.link-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 1 auto;
  // height: 5rem;
  label {
    width: 100%;
    margin: unset;
  }
  span {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 2.5rem;
    justify-content: center;
  }
  input {
    width: calc(100% - 4rem);
    // height: 2.5rem;
    padding-left: 0.5rem;
    border: 1px solid #3c3c3b3a;
    border-radius: unset;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  button {
    padding: 0;
    border-radius: unset;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.list__options {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: baseline;
  .input {
    max-width: 64px;
    padding-left: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}

.list__controls,
.pagination-control {
  display: flex;
}

.list__controls {
  grid-column: 1 / 3;
  justify-content: center;
  margin-bottom: 20px;
}

.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}

/* Normalises button styling to fix next to search bar. */
.button.survey-links {
  margin-top: -5px;
  height: 2.6rem;
  line-height: 1rem;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
