
@import "@/assets/styles/variables.scss";

.carer-feedback-container {
  background-color: white;
  height: 100%;
  padding: $padding1x;
  .carer-feedback-box {
    width: $container-width;
    margin: 0 auto;
    padding: 50px 0;
    .title {
      .survey-title {
        margin: 0;
        font-weight: 600;
        font-size: 1.25rem;
      }
    }
    textarea.textarea {
      border-radius: $content-border-radius;
      border: $content-border;
      height: 100%;
      font-size: 1rem;
      outline: none;
      resize: none;
      overflow: auto;
      padding: 10px;
      width: 100%;
      &,
      &::placeholder {
        font-family: AllRoundGothicBook;
      }
    }
  }
}
