
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';
@import '@/assets/styles/sections.scss';

.table-data-container {
  margin-top: 0.5rem;
  padding: 1rem;
  > * {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    > * {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > :not(:first-child):not(:last-child) {
      flex: 1 0 220px;
      a {
        display: block;
        text-align: center;
      }
    }
    > :first-child {
      flex: 0 0 92px;
    }
    > :last-child {
      flex: 0 0 25px;
    }
  }
  > :nth-child(2n) {
    background: whitesmoke;
  }
}

.empty-state-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-table-container {
  display: flex;
  flex-direction: row;
  // overflow-x: scroll;
}

.table-size {
  width: 400px;
}

td,
th {
  height: 3rem;
  text-align: center;
}

tr:nth-child(even) {
  background: #f8f8f8;
}
tr:nth-child(odd) {
  background: #fff;
}

.list {
  &__container {
    grid-area: list;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  &__options {
    justify-self: flex-end;
    .input {
      max-width: 64px;
      padding-left: 1rem;
      font-weight: 600;
    }
  }
  &__header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 1rem 0;
    place-items: center;
    font-weight: 600;
    color: darkgrey;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-flow: column;
    &--item {
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 1rem 0;
      border: 1px solid #eeedec;
      border-radius: 4px;
      box-shadow: none;
      // &:not(:last-of-type) {
      margin-bottom: 1rem;
      // }
      transition: box-shadow 200ms;
      &:hover {
        box-shadow: 0px 4px 8px #eeedec;
      }
    }
  }
  &__controls {
    justify-content: space-between;
  }
}

.round {
  border-radius: 50%;
  width: 22.5px;
  height: 22.5px;
  // margin-bottom: 7px;
}
.collapsed-data {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  max-height: 58px;
  place-items: center;
}
.expanded-data {
  max-height: 0;
  height: 100%;
  transition: height 200ms ease-in-out;
}
.show-expanded {
  max-height: 600px;
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.plan-details {
  // display: flex;
  // flex-flow: row;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  padding: 1rem;
}
.sections {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.section-header {
  width: 100%;
  text-align: center;
  line-height: 2rem;
  background-color: white;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  // margin-top: -0.5rem;
  // margin-bottom: 0.5rem;
}

.section-header.grades {
  position: relative;
  color: inherit;
  font-weight: 600;
  text-transform: uppercase;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
  }
}

.audited-by {
  text-transform: none;
  font-weight: 400 !important;
}

.review-author {
  text-transform: none;
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.search-bar {
  max-width: 400px;
}

.dateFilter {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.outcome {
  &__Gold::after {
    border-bottom: 2px solid $gold-colour;
  }
  &__Silver::after {
    border-bottom: 2px solid $silver-colour;
  }
  &__Bronze::after {
    border-bottom: 2px solid $bronze-colour;
  }
}
// .outcome-outstanding::after {
//   border: 2px solid $outstanding-color;
// }
// .outcome-good::after {
//   border: 2px solid $good-color;
// }
// .outcome-requires_improvement::after {
//   border: 2px solid $ri-color;
// }
// .outcome-inadequate::after {
//   border: 2px solid $inadequate-color;
// }

.moderation-results {
  display: grid;
  gap: 10px;
  grid-template-columns: 100px repeat(3, 1fr) 50px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  // align-items: flex-start;
  // justify-content: center;
  // width: 100%;
  // height: 100%;
  // overflow-x: scroll;
  // scroll-snap-type: x proximity;
}

.moderation-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // scroll-snap-align: center;
  background: #eeedec;
  max-height: 200px;
  // padding: 0.5rem 0;
  // overflow-x: scroll;
}

.key-lookup,
.diffs {
  max-width: 100px;
  background-color: #eeedec50;
}

.list__controls {
  grid-column: 1 / 2;
  justify-content: center;
  margin-bottom: 20px;
}

.list__controls,
.pagination-control {
  display: flex;
}
.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}
.section-result {
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

// .key-lookup
.section-id {
  padding: 4px;
  // &__diffs {
  //   height: 1rem;
  //   line-height: 2rem;
  // }
}

.list__content.empty {
  margin-top: 10vh;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
