
@import "@/assets/styles/variables.scss";

.feedback-container {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  .feedback-box {
    margin: 0 auto;
    width: fit-content;
    text-align: center;
    .title {
      i {
        font-weight: 100;
        font-size: 5rem;
        padding: 1rem;
        color: $brand-green;
      }
      .survey-title {
        margin: 0;
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }
}
