
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.user-details-container {
  display: flex;
  flex-direction: row;
  .user-details {
    width: 350px;
    margin-right: 1rem;
  }
  .user-audit-details {
    width: 450px;
    min-height: 100%;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .is-danger {
    background: red;
  }
  .is-info {
    background: #007bff;
  }
}
